// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("easy-autocomplete")
require("popper.js")

import "css/application"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@fortawesome/fontawesome-free/css/all"
//import 'tableexport.jquery.plugin/tableExport.min'
//import 'bootstrap-table'
import BootstrapTable from "bootstrap-table/src/bootstrap-table";
window.BootstrapTable = BootstrapTable
//import 'bootstrap-table/dist/extensions/export/bootstrap-table-export.min'

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

import "packs/utils"
import "packs/charts"

